import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import EmailListForm from "../components/email/EmailListForm"
import SEO from "../components/seo"
import "./blogTemplate.scss"
import { DiscussionEmbed } from 'disqus-react'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: frontmatter.title, title: frontmatter.title },
  }
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        keywords={frontmatter.keywords} />
      <div className="markdownBody">
        <div className="blog-post">
          <h1>{frontmatter.title}</h1>
          <h5>{frontmatter.date}</h5>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <EmailListForm />
      <DiscussionEmbed {...disqusConfig} />
    </Layout >

  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        keywords
      }
    }
  }
`
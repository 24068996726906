import React, { useState } from "react"
import * as styles from "./EmailListForm.module.scss"
import addToMailchimp from "gatsby-plugin-mailchimp"

const EmailListForm = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const data = await addToMailchimp(email, { FNAME: name })
      alert(data.result)
    } catch (error) {
      alert("error")
    }
  }

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  return (
    <form className={styles.EmailListForm} onSubmit={handleSubmit}>
      <h2>Subscribe to my email list</h2>
      <div controlId="formBasicName">
        <label htmlFor="formBasicName">Name</label>
        <input
          className={styles.emailFormInput}
          type="text"
          placeholder="Enter name"
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div controlId="formBasicEmail">
        <label htmlFor="formBasicEmail">Email address</label>
        <input
          className={styles.emailFormInput}
          type="email"
          placeholder="Enter email"
          onChange={handleEmailChange}
        />
      </div>

      <button className="btn-action" type="submit">
        Submit
      </button>
    </form>
  )
}

export default EmailListForm
